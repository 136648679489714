import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithInput.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureWithSteps from "components/features/TwoColWithSteps.js";
import Pricing from "components/pricing/ThreePlans.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import FAQ from "components/faqs/SingleCol.js";
import GetStarted from "components/cta/GetStarted";
import Footer from "components/footers/FiveColumnWithBackground.js";
import heroScreenshotImageSrc from "images/customer_retention.jpg";
import macHeroScreenshotImageSrc from "images/dashboard.jpg";
import prototypeIllustrationImageSrc from "images/salesai.png";
import customerIllustrationImageSrc from "images/customer.jpg";
import { ReactComponent as BriefcaseIcon } from "feather-icons/dist/icons/briefcase.svg";
import { ReactComponent as GiftIcon } from "feather-icons/dist/icons/gift.svg";
import { ReactComponent as TrackIcon } from "feather-icons/dist/icons/navigation-2.svg";
import { ReactComponent as UserIcon } from "feather-icons/dist/icons/users.svg";
import { ReactComponent as SearchIcon } from "feather-icons/dist/icons/search.svg";
import { FaHandshake } from "react-icons/fa";

import { FaStore } from "react-icons/fa";
import SimpleContactUs from "components/forms/SimpleContactUs";
import TwoColContactUsWithIllustration from "components/forms/TwoColContactUsWithIllustration";
import TwoColContactUsWithIllustrationFullForm from "components/forms/TwoColContactUsWithIllustrationFullForm";

export default () => {
  const Subheading = tw.span`uppercase tracking-widest font-bold text-primary-500`;
  const HighlightedText = tw.span`text-primary-500`;

  return (
    <AnimationRevealPage>
      <Hero roundedHeaderButton={true} />
      <Features
        subheading={<Subheading>Features</Subheading>}
        heading={
          <>
            Why Choose <HighlightedText>Reveza</HighlightedText>?
          </>
        }
      />
      <MainFeature
        subheading={<Subheading>Elevate Sales</Subheading>}
        imageSrc={heroScreenshotImageSrc}
        imageBorder={true}
        imageDecoratorBlob={true}
        textOnLeft={true}
      />
      <MainFeature2
        textOnLeft={false}
        subheading={<Subheading>Solution</Subheading>}
        heading={
          <>
            Increase <HighlightedText>Sales</HighlightedText>,{" "}
            <HighlightedText>Retention</HighlightedText> &{" "}
            <HighlightedText>Engagement</HighlightedText>
          </>
        }
        description="Reveza offers a comprehensive AI-powered solution that enhances customer experiences and operational efficiency, driving at least a 10% increase in store sales. "
        imageSrc={prototypeIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: TrackIcon,
            title: "Track",
            description:
              "Track each customer’s unique in-store & online experience",
            iconContainerCss: tw`bg-black text-white`,
          },
          {
            Icon: UserIcon,
            title: "Determine",
            description: "Determine if they are new or existing customers",
            iconContainerCss: tw`bg-black text-white`,
          },
          {
            Icon: SearchIcon,
            title: "Identify",
            description: "Identify customers as soon as they enter your store ",
            iconContainerCss: tw`bg-black text-white`,
          },
          {
            Icon: GiftIcon,
            title: "Gratify",
            description: "Instantly Gratify your customers based on loyalty",
            iconContainerCss: tw`bg-black text-white`,
          },
        ]}
      />

      <MainFeature2
        textOnLeft={true}
        subheading={<Subheading></Subheading>}
        heading={
          <>
            <HighlightedText>Personalized</HighlightedText>, data-driven{" "}
            <HighlightedText>customer interactions</HighlightedText> both
            in-store and online
          </>
        }
        description="By instantly identifying and distinguishing between new and returning visitors, Reveza enables personalized, data-driven customer interactions both in-store and online. 
"
        imageSrc={customerIllustrationImageSrc}
        showDecoratorBlob={false}
        features={[
          {
            Icon: FaHandshake,
            title: "Engages customers",
            description:
              "Using dynamic language models, it engages customers based on their tone and provides instant gratification tailored to their loyalty. ",
            iconContainerCss: tw`bg-black text-white`,
          },
          {
            Icon: FaStore,
            title: "Online to Offline integration",
            description:
              "Reveza also seamlessly integrates online-to-offline experiences using cookie-based tracking, while its interactive displays elevate the in-store shopping experience",
            iconContainerCss: tw`bg-black text-white`,
          },
        ]}
      />

      <FeatureWithSteps
        subheading={<Subheading>STEPS</Subheading>}
        heading={
          <>
            <HighlightedText>Get Started</HighlightedText>
          </>
        }
        textOnLeft={false}
        imageSrc={macHeroScreenshotImageSrc}
        imageDecoratorBlob={true}
        decoratorBlobCss={tw`xl:w-40 xl:h-40 opacity-15 -translate-x-1/2 left-1/2`}
      />

      {/* <Pricing
        subheading={<Subheading>Pricing</Subheading>}
        heading={
          <>
            Reasonable & Flexible <HighlightedText>Plans.</HighlightedText>
          </>
        }
        plans={[
          {
            name: "Personal",
            price: "$17.99",
            duration: "Monthly",
            mainFeature: "For Individuals",
            features: [
              "30 Templates",
              "7 Landing Pages",
              "12 Internal Pages",
              "Basic Assistance",
            ],
          },
          {
            name: "Business",
            price: "$37.99",
            duration: "Monthly",
            mainFeature: "For Small Businesses",
            features: [
              "60 Templates",
              "15 Landing Pages",
              "22 Internal Pages",
              "Priority Assistance",
            ],
            featured: true,
          },
          {
            name: "Enterprise",
            price: "$57.99",
            duration: "Monthly",
            mainFeature: "For Large Companies",
            features: [
              "90 Templates",
              "27 Landing Pages",
              "37 Internal Pages",
              "Personal Assistance",
            ],
          },
        ]}
      /> */}
      {/* <Testimonial
        subheading={<Subheading>Testimonials</Subheading>}
        heading={
          <>
            Our Clients <HighlightedText>Love Us.</HighlightedText>
          </>
        }
        testimonials={[
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3.25&w=512&h=512&q=80",
            heading: "Amazing User Experience",
            quote:
              "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.",
            customerName: "Charlotte Hale",
            customerTitle: "Director, Delos Inc.",
          },
          {
            stars: 5,
            profileImageSrc:
              "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=512&h=512&q=80",
            heading: "Love the Developer Experience and Design Principles !",
            quote:
              "Sinor Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
            customerName: "Adam Cuppy",
            customerTitle: "Founder, EventsNYC",
          },
        ]}
      /> */}
      {/* <FAQ
        subheading={<Subheading>FAQS</Subheading>}
        heading={
          <>
            You have <HighlightedText>Questions ?</HighlightedText>
          </>
        }
        faqs={[
          {
            question: "Are all the templates easily customizable ?",
            answer:
              "Yes, they all are. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          },
          {
            question:
              "How long do you usually support an standalone template for ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          },
          {
            question: "What kind of payment methods do you accept ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          },
          {
            question:
              "Is there a subscribption service to get the latest templates ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          },
          {
            question: "Are the templates compatible with the React ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          },
          {
            question: "Do you really support Internet Explorer 11 ?",
            answer:
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
          },
        ]}
      /> */}

      <GetStarted />
      <TwoColContactUsWithIllustrationFullForm></TwoColContactUsWithIllustrationFullForm>
      <Footer />
    </AnimationRevealPage>
  );
};
